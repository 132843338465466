import React from "react";
import { StaticQuery, graphql } from 'gatsby'

export const PortfolioOrder = (props) => (
  <div {...props} properties={props.properties} />
)

export default (props) => (
  <StaticQuery
    query={
      graphql`
        query portftolioOrderQuery {
          markdownRemark(frontmatter: {templateKey: {eq: "portfolio-order"}}) {
          frontmatter {
            properties {
              property {
                hotelName
              }
            }
          }
        }
      }
    `}
    render={(data) => <PortfolioOrder {...props} properties={data.markdownRemark.frontmatter} />}
  />
);

